<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="project_key"
        type="autocomplete"
        :values="projects"
        name="Iniziativa"
        hint="Cerca per iniziativa"
        dense
      />

      <FormItem
        v-model="type"
        type="autocomplete"
        :values="types"
        name="Tipo"
        hint="Ricerca per tipo"
        dense
      />
      <FormItem
        v-model="status"
        type="autocomplete"
        :values="statuses"
        name="Stato"
        hint="Ricerca per stato"
        dense
      />

      <FormItem
        v-model="resolution"
        type="autocomplete"
        :values="resolutionsByStatus(status)"
        name="Esito"
        hint="Ricerca per esito"
        dense
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'sessions/getFiltersFields',
  mutationType: 'sessions/SET_FILTER_FIELDS',
})

export default {
  name: 'SessionsSectionSearch',
  components: { FormItem, BaseSectionSearch },
  computed: {
    ...mapFields([
      'type',
      'status',
      'resolution',
      'project_key',
      'customer_address',
    ]),
    ...mapState('sessions', ['statuses', 'types']),
    ...mapGetters('sessions', ['resolutionsByStatus']),
    ...mapState('adminProjects', {
      projects: 'list',
    }),
  },
  methods: {
    ...mapActions('sessions', ['getDevicesTypes']),
    ...mapMutations('sessions', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>
