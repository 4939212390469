<template>
  <v-container fluid>
    <SessionsSectionSearch @search="refresh" />
    <BaseTable
      ref="table"
      title="Sessioni"
      module="sessions"
      :headers="headers"
      :exportable="canUser('sessions', 'download')"
    >
      <template v-slot:item.project_key="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="primary"
              dark
              class="my-6 font-weight-bold"
              label
              v-bind="attrs"
              v-on="on"
            >
              {{ item.project_key }}
            </v-chip>
          </template>
          <span>{{ item.project.name }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.created_by="{ item }">
        {{ item.created_by | placeholder('Cliente') }}
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" dark class="my-6">
          {{ statusesMap[item.status] }}
        </v-chip>
      </template>
      <template v-slot:item.type="{ item }">
        {{ typesMap[item.type] }}
      </template>
      <template v-slot:item.resolution="{ item }">
        {{ resolutionsMap[item.resolution] | placeholder('-') }}
      </template>
      <template v-slot:item.customer_address="{ item }">
        <span v-if="item.type === 'video'">
          {{ item.customer_address | placeholder('-') }}
        </span>
        <span v-if="item.type === 'remote'">
          {{ item.project_key }}-{{ item.session_code }}-{{ item.client_code }}
        </span>
      </template>

      <template v-slot:item.duration="{ item }">
        {{ item.duration | duration }}
      </template>
    </BaseTable>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import BaseTable from '@components/structure/base-table.vue'
import SessionsSectionSearch from '@components/sessions/SessionsSectionSearch.vue'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'SessionsSearch',
  components: { SessionsSectionSearch, BaseTable },
  data: () => ({
    headers: [
      {
        text: 'Progetto',
        value: 'project_key',
      },
      {
        text: 'Tipo',
        value: 'type',
      },
      {
        text: 'ID Esterno',
        value: 'external_id',
      },
      {
        text: 'Stato',
        value: 'status',
      },
      {
        text: 'Esito',
        value: 'resolution',
      },
      {
        text: 'Creata',
        value: 'created_at',
      },
      {
        text: 'Chiusura',
        value: 'closed_at',
      },
      {
        text: 'Creata da',
        value: 'created_by',
      },
      {
        text: 'Chiusa da',
        value: 'closed_by',
      },
      {
        text: 'Durata',
        value: 'duration',
      },
    ],
  }),
  computed: {
    ...authComputed,
    ...mapGetters('sessions', ['statusesMap', 'resolutionsMap', 'typesMap']),
  },
  mounted() {
    this.setScopes(['project'])
  },
  destroyed() {
    this.setScopes([])
  },
  methods: {
    ...mapMutations('sessions', {
      setScopes: 'SET_SCOPES',
    }),
    getColor(status) {
      switch (status) {
        case 'waiting':
          return 'gray'
        case 'offline':
          return 'red'
        default:
          return 'green'
      }
    },
    refresh() {
      this.$refs.table.refresh()
    },
  },
}
</script>
