var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('SessionsSectionSearch',{on:{"search":_vm.refresh}}),_c('BaseTable',{ref:"table",attrs:{"title":"Sessioni","module":"sessions","headers":_vm.headers,"exportable":_vm.canUser('sessions', 'download')},scopedSlots:_vm._u([{key:"item.project_key",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"my-6 font-weight-bold",attrs:{"color":"primary","dark":"","label":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.project_key)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.project.name))])])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.created_by,'Cliente'))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-6",attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.statusesMap[item.status])+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.typesMap[item.type])+" ")]}},{key:"item.resolution",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(_vm.resolutionsMap[item.resolution],'-'))+" ")]}},{key:"item.customer_address",fn:function(ref){
var item = ref.item;
return [(item.type === 'video')?_c('span',[_vm._v(" "+_vm._s(_vm._f("placeholder")(item.customer_address,'-'))+" ")]):_vm._e(),(item.type === 'remote')?_c('span',[_vm._v(" "+_vm._s(item.project_key)+"-"+_vm._s(item.session_code)+"-"+_vm._s(item.client_code)+" ")]):_vm._e()]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item.duration))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }